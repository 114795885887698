import React, {Component} from 'react'
import Lottie from 'react-lottie';
import * as long from '../logo/animated_long.json'
import * as short from '../logo/animated_short.json'

class LottieLogo extends Component {

  state = {
    isPaused: true
  }

  componentDidMount(){

    setTimeout(function () {
      this.setState({
        isStopped:false
      })
    }.bind(this), 600);
  }

  render(){
    let type = long.default
    if(this.props.type === "short"){
      type = short.default
    }
    const defaultOptions = {
      animationData: type,
      autoplay: false,
      loop: false
    }
    return (
      <aside id="lottieLogo" className="lottie-logo">
        <Lottie
          className="lottie-logo"
          options={defaultOptions}
          isStopped={this.state.isStopped}
        />
      </aside>
    )
  }

}

export default LottieLogo
