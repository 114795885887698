
import {ContactInfo} from './ContactInfo'


export const ListContactInfo = () => {
  const contactInfo = ContactInfo().allWordpressAcfOptions;

  let contacts = [];
  let socialMedia = [];

  contactInfo.edges.map((postData)=>{

    let options = postData.node.options.options_contact;
    let social_media = postData.node.options.options_social_media;
    if(options){
      options.map((opt)=>{
        const {options_contact_name, options_contact_value} = opt;
        const contact = {
          name: options_contact_name,
          value: options_contact_value
        }
        contacts.push(contact)
      })
    }
    if(social_media){
      social_media.map((opt)=>{
        const {options_social_media_name, options_social_media_link} = opt;
        const contact = {
          name: options_social_media_name,
          link: options_social_media_link
        }
        socialMedia.push(contact)
      })
    }
  })

  let allContacts = {
    contacts:contacts,
    socialMedia:socialMedia
  }

  return allContacts;
}
