/**
 * Footer component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Link} from "gatsby";
import {ListContactInfo} from '../Functions/ListContactInfo.js'

import "../styles/Footer.scss";


const Footer = ({data}) => {

  let contacts = ListContactInfo();


  let menuItems = data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => {
    const url = item.url.replace('http://susadals.nyinteractive.se', '')
    return (
      <li key={item.object_id} className={item.title !=="Kontakt" ? "menu-item" : "hidden"}>
        {item.object === 'custom' ?
          <a href={url} target="_blank" rel="noreferrer">{item.title}</a>
        :
          <Link to={url}>{item.title}</Link>
        }
      </li>
    )
  })


  return (

      <footer className="site-footer ">

          <div className="flex--row flex--spread">
            <blockquote>Jag tror på mat som är mat, <br />och att rörelse tar oss framåt.</blockquote>
            <ul className="contact-info">
            {
              Object.keys(contacts.contacts).map((key)=>(
                <li key={"contact-"+key}><a className="link-arrow" href={contacts.contacts[key].value}>{contacts.contacts[key].name}</a></li>
              ))
            }
            </ul>
            <ul className="social-info">
            {
              Object.keys(contacts.socialMedia).map((key)=>(
                <li key={"social-"+key}><a className="link-arrow--after" href={contacts.socialMedia[key].link}>{contacts.socialMedia[key].name}</a></li>
              ))
            }
            </ul>
          </div>
          <nav className="footer-menu">
            <ul className="flex--row flex--start menu-items">
              {menuItems}
            </ul>
          </nav>

      </footer>

  )
}


export default Footer
