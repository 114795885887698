import React, {Component} from 'react'
import Lottie from 'react-lottie';
import * as long from '../logo/animated_long.json'
import * as short from '../logo/animated_short.json'

class LottieLogo extends Component {

  state = {
    isPaused: true
  }

  componentDidMount(){
    if(!this.props.startOnEvent){
      window.addEventListener('scroll', this.handleScroll)
      this.checkScroll(window.pageYOffset)
    }

  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    this.checkScroll()
  }

  checkScroll = () => {
    let element = document.getElementById('lottieLogo');
    if(!element)return false;

    let rect = element.getBoundingClientRect()
    let h = window.innerHeight
    // console.log('rect: ', rect);
    if( window.pageYOffset!== 0 && ( h+window.pageYOffset > rect.bottom +400 ) ){

      this.setState({
        isPaused: false
      })
    }
  }
  render(){
    let type = long.default
    if(this.props.type === "short"){
      type = short.default
    }
    const defaultOptions = {
      animationData: type,
      autoplay: false,
      loop: false
    }
    return (
      <aside id="lottieLogo" className="lottie-logo">
        <Lottie
          className="lottie-logo"
          options={defaultOptions}
          isStopped={this.state.isStopped}
        />
      </aside>
    )
  }

}

export default LottieLogo
