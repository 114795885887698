import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {Component} from "react"
import Menu from "./mainmenu"
import "../styles/header.scss"
// import Logo from '../images/Susanne_Dalsatt.svg';
import Logo from '../logo/Susanne_namnbild.svg';

class Header extends Component {

  state = {
    menuBackground: false
  }

  toggleMenu = () => {
    let menuActive = !this.props.menuActive;
    this.props.changeMenuStatus(menuActive);

  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll)
    this.checkScroll(window.pageYOffset)
    let element = document.getElementById('showMenu')|| false;
    if(!element)return false;
    // let rect = element.getBoundingClientRect();

  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (e) => {
    let _this = this
    if(typeof window !== 'undefined'){
      window.onscroll = function() {
        _this.checkScroll(window.pageYOffset)
      }
    }
  }

  checkScroll = (yPosition) => {
    let element = document.getElementById('showMenu')
    if(!element)return false;
    
    let rect = element.getBoundingClientRect()

    if(yPosition > rect.top){
      this.setState({
        menuBackground: true
      })
    }else{
      this.setState({
        menuBackground: false
      })
    }
  }


  render(){
    let headerClass = 'site-header'
    if(this.props.menuActive){
      headerClass = 'site-header menu--open'
    }else{
      headerClass = this.state.menuBackground ? 'site-header' : 'site-header transparent'
    }

    return (

      <header className={headerClass}>

            <Link to="/">
              <img src={Logo} className="top-logo" alt=""/>
            </Link>

          <Menu menuActive={this.props.menuActive} toggleMenu={this.toggleMenu} />
      </header>
    )
  }

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
