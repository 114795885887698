import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import LottieLogo from '../logo/menulogo'

const Menu = (props) => {

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allWordpressWpApiMenusMenusItems {
        edges {
          node {
            name
            slug
            items {
              url
              title
              object
              object_id
            }
          }
        }
      }
    }
  `)

  let url
  let menuItems = data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => {
    url = item.url.replace('http://susadals.nyinteractive.se', '');
    // console.log('item', item);
    return (
      <li key={item.object_id} className="menu-item">
        {item.object !== "custom" ?
          <Link to={url}>{item.title}</Link>
        :
          <a href={url} target="_blank" rel="noreferrer" >{item.title}</a>

        }
      </li>
    )
  })

  let logo = props.menuActive ? <LottieLogo /> : false


  return(
    <nav className="header-menu">
      <button className="menu-button" onClick={props.toggleMenu} >Menu</button>

      <div className="menu-content">
        <div className="logo">
          {logo}
        </div>
        <ul className={props.menuActive ? "main-menu active" : "main-menu" }>
          {menuItems}
        </ul>
      </div>
    </nav>
  )
}

export default Menu
