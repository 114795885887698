
import { useStaticQuery, graphql } from "gatsby"

export const ContactInfo = () => {
  const contacts = useStaticQuery(
    graphql`
     {
      allWordpressAcfOptions {
        edges {
          node {
            options {
              options_contact {
                options_contact_name
                options_contact_value
              }
              options_social_media {
                options_social_media_name
                options_social_media_link
              }
            }
          }
        }
      }
    }
    `
  )
  return contacts
}
