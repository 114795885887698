/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import "./reset.css"
import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"


import Header from "./header"
import Footer from "./Footer"
import Cookies from "./Cookies/Cookies"
import "./layout.scss"
import "../styles/base.scss";
// import ScrollPoints from '../Functions/ScrollPoints'

const ScrollPoints = React.lazy(() => import("../Functions/ScrollPoints.js"));


const Layout = (props) => {
  const children = props.children;
  const [menuOpen, changeStatus] = React.useState(false);

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
      allWordpressWpApiMenusMenusItems {
        edges {
          node {
            name
            slug
            items {
              url
              title
              object
              object_id
            }
          }
        }
      }
    }
  `)
  let url;
  data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(item => {
    url = item.url.replace('http://susadals.nyinteractive.se', '')
    return (
      <li key={item.object_id} className="menu-item">
        <Link to={url}>{item.title}</Link>
      </li>
    )
  })

   // React.useEffect(() => {
   //   ScrollPoints(['.scroll-point']);
   // });

   const menuStatus = (status) =>{

     if(typeof status === 'boolean' || status instanceof Boolean){
       changeStatus(status)
     }
     else {
       changeStatus(false)
       return true;
     }
     // console.log('menuOpen:', newStatus);
   }

  const isSSR = typeof window === "undefined";
console.log('menuOpen', menuOpen);
  return (
    <>
    <noscript dangerouslySetInnerHTML={{
      __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MN72JJV" height="0" width="0"
      style="display:none;visibility:hidden"></iframe>
      `,
    }}
    />
      <Header changeMenuStatus={menuStatus} menuActive={menuOpen} siteTitle={data.site.siteMetadata.title} />

      <main onClick={menuStatus} className={"slug-"+props.slug}>{children}</main>
      <Footer data={data} />

      {!isSSR && (
      <React.Suspense fallback={<div>Loading...</div>}>
          <ScrollPoints />
          <Cookies />

      </React.Suspense>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
